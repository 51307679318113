import {useEffect, useRef, useState} from "react";
import HomeDesktopTopBar from "../components/bars/homeScreen/HomeDesktopTopBar";
import {PageView} from "../enums/PageView";
import HomeShowCase from "../components/others/homeScreen/HomeShowCase";
import HomeTabletTopBar from "../components/bars/homeScreen/HomeTabletTopBar";
import HomeDrawer from "../components/others/homeScreen/HomeDrawer";
import {useResizeListener} from "../listeners/SystemResizeListener";
import {recordPageViewMetric} from "../services/AnalyticsRecorder";

function HomeScreen() {
    // Landing Screen used in App to display App purpose and functionalities

    // PROPERTIES
    // ---- PageViewMetric
    const hasTrackedView = useRef(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    // HOOKS
    // ---- At rendering : Record page view metric
    useEffect(() => {
        if (!hasTrackedView.current) {
            // Avoid double trigger in dev, due to React Strict mode
            recordPageViewMetric(PageView.LANDING);
            hasTrackedView.current = true;
        }
    }, []);

    // HOOKS
    // ---- Set listener for potential open Modal during screen resize
    useResizeListener(() => setIsDrawerOpen(false))

    // BUILD
    return(
        <div className={"animate-appearSlow bg-backgroundLight1 overflow-auto w-full h-[100svh] flex flex-col justify-start items-center"}>
            {/** MODAL */}
            <HomeDrawer open={isDrawerOpen}
                        handleClose={() => setIsDrawerOpen(false)} />
            {/** CONTENT */}
            <HomeTabletTopBar openDrawer={openDrawer} />
            <HomeDesktopTopBar openDrawer={openDrawer} />
            <HomeShowCase />
        </div>
    );

    // METHODS
    // ---- Open modal
    function openDrawer() {
        setIsDrawerOpen(true)
    }
}

// EXPORT
export default HomeScreen