import ParameterTabletBottomBar from "../../../bars/flashScreens/parameterStepScreen/ParameterTabletBottomBar";
import {routes} from "../../../../constants/routes";
import ParameterDesktopBottomBar from "../../../bars/flashScreens/parameterStepScreen/ParameterDesktopBottomBar";
import {useNavigate} from "react-router-dom";
import {kFakeFlashGrades, kFakeQuestions, kFakeSubthemesWithLevelAs} from "../../../../services/Faker";
import {get_flash_grades} from "../../../../api/grade";
import {isArrayNullUndefinedOrEmpty, isNullOrUndefined} from "../../../../services/Validator";
import {get_subthemes_with_levelAs_from_grade} from "../../../../api/subtheme";
import {get_questions_from_levelAsIds} from "../../../../api/question";
import {useEffect, useState} from "react";
import ParameterCoreContent from "./ParameterCoreContent";
import {
    kSystemGradesError,
    kFlashQuestionsError, kFlashSubthemesError, kEmptyResponseError
} from "../../../../constants/errors";
import SystemScreenError from "../../../errors/SystemScreenError";
import SystemNeoScreenLoader from "../../../loaders/SystemNeoScreenLoader";
import {sendErrorToSentry} from "../../../../services/Sentry";
import {Environment} from "../../../../enums/Environment";

function ParameterContent({selectedGrade, setFlashGrade, fetchQuestionsCallback}) {
    // Used in ParameterStepScreen to handle flash parameters selection
    // which is made of Grade and Subthemes selection

    // PROPERTIES
    let navigate = useNavigate();
    // ---- Grades
    const [isGradesLoading, setIsGradesLoading] = useState(true)
    const [gradesError, setGradesError] = useState(null)
    const [grades, setGrades] = useState([])
    // ---- Subthemes
    const [isSubthemesLoading, setIsSubthemesLoading] = useState(false)
    const [subthemesError, setSubthemesError] = useState(null)
    const [subthemes, setSubthemes] = useState([])
    const [selectedSubtheme, setSelectedSubtheme] = useState(null)
    const [levelAs, setLevelAs] = useState([])
    const [selectedLevelAs, setSelectedLevelAs] = useState([])
    // ---- Questions
    const [isQuestionsLoading, setIsQuestionsLoading] = useState(false)
    const [questionsError, setQuestionsError] = useState(null)

    // HOOKS
    // ---- Triggered at rendering : set Grades
    useEffect(() => {
        getFlashGrades()
    }, [])

    // BUILD
    if (!isNullOrUndefined(gradesError))
        return <SystemScreenError error={gradesError}
                                  retryCallback={() => getFlashGrades()} />
    if (!isNullOrUndefined(questionsError))
        return <SystemScreenError error={questionsError}
                                  retryCallback={() => fetchFlashQuestions()} />
    if (isGradesLoading)
        return <SystemNeoScreenLoader />
    return (
        <div className={"animate-appearSlow w-full grow flex flex-col justify-start items-center space-y-24 pb-24"}>
                <ParameterCoreContent grades={grades}
                                      selectedGrade={selectedGrade}
                                      setFlashGrade={getSubthemesWithLevelAsFromGrade}
                                      isSubthemesLoading={isSubthemesLoading}
                                      subthemesError={subthemesError}
                                      subthemes={subthemes}
                                      selectSubtheme={selectSubtheme}
                                      selectedSubtheme={selectedSubtheme}
                                      levelAs={levelAs}
                                      selectedLevelAs={selectedLevelAs}
                                      toggleLevelA={toggleLevelA} />
                {/** BOTTOM BARS*/}
                <ParameterTabletBottomBar selectedGrade={selectedGrade}
                                          selectedLevelAs={selectedLevelAs}
                                          fetchFlashQuestions={fetchFlashQuestions}
                                          isFetchingLoading={isQuestionsLoading}
                                          goToPreviousStep={() => navigate(routes.home)}
                                          extraClass={"mt-24"} />
                <ParameterDesktopBottomBar selectedGrade={selectedGrade}
                                           selectedLevelAs={selectedLevelAs}
                                           fetchFlashQuestions={fetchFlashQuestions}
                                           isFetchingLoading={isQuestionsLoading}
                                           goToPreviousStep={() => navigate(routes.home)}
                                           extraClass={"mt-24"} />
        </div>
    );

    // METHODS
    // ---- ---- ----
    // API CALLS
    // ---- ---- ----
    // ---- Fetch flash levels from the api
    function getFlashGrades() {
        // <<<< For .env.faker ----
        if (process.env.REACT_APP_ENV === Environment.FAKER) {
            setGrades(kFakeFlashGrades)
            setIsGradesLoading(false)
            setGradesError(null)
            return
        }
        // ---- For .env.faker >>>>

        // Switch loader on and clear error
        setIsGradesLoading(true)
        setGradesError(null)
        // Perform
        get_flash_grades()
            .then((res) => {
                if (isNullOrUndefined(res) || isArrayNullUndefinedOrEmpty(res)) {
                    setGradesError(kEmptyResponseError)
                    sendErrorToSentry(kEmptyResponseError.sentry, "fetching Grades from ParameterContent")
                } else {
                    setGrades(res)
                }
            }).catch((err) => {
                console.error(err)
                setGradesError(kSystemGradesError)
                sendErrorToSentry(kSystemGradesError.sentry, "ParameterContent")
        }).finally(() => {
            setIsGradesLoading(false)
        })
    }

    // ---- Fetch Subthemes with LevelAs based on a specific Grade and Theme
    function getSubthemesWithLevelAsFromGrade(selectedGrade) {
        // <<<< For .env.faker ----
        if (process.env.REACT_APP_ENV === Environment.FAKER) {
            setSubthemes(kFakeSubthemesWithLevelAs)
            setFlashGrade(selectedGrade)
            setIsSubthemesLoading(false)
            setSubthemesError(null)
            return
        }
        // ---- For .env.faker >>>>

        // Check input parameters
        if (isNullOrUndefined(selectedGrade?.id))
            return;
        // Perform setFlashGrade from FlashScreen
        setFlashGrade(selectedGrade)
        // Reset screen state
        resetScreenState()
        // Switch loader on and clear error
        setIsSubthemesLoading(true)
        setSubthemesError(null)
        // Perform request
        get_subthemes_with_levelAs_from_grade(selectedGrade?.id)
            .then((res) => {
                if (isNullOrUndefined(res) || isArrayNullUndefinedOrEmpty(res)) {
                    setSubthemesError(kEmptyResponseError)
                    sendErrorToSentry(kEmptyResponseError.sentry, "fetching Subthemes from ParameterContent")
                } else {
                    setSubthemes(res)
                }
            }).catch((err) => {
            console.error(err)
            setSubthemesError(kFlashSubthemesError)
            sendErrorToSentry(kFlashSubthemesError.sentry)
        }).finally(() => {
            setIsSubthemesLoading(false)
        })
    }

    // ---- Api call : fetch questions
    function fetchFlashQuestions() {
        // <<<< For .env.faker ----
        if (process.env.REACT_APP_ENV === Environment.FAKER) {
            fetchQuestionsCallback(kFakeQuestions)
            setIsQuestionsLoading(false)
            setQuestionsError(null)
            return
        }
        // ---- For .env.faker >>>>
        // If no levelId or already loading, return
        if (isArrayNullUndefinedOrEmpty(selectedLevelAs) || isQuestionsLoading)
            return
        // Switch loader on and clear error
        setIsQuestionsLoading(true)
        setQuestionsError(null)
        // Prepare request body
        let data = {
            levelAsIds: selectedLevelAs.map(levelA => levelA.id)
        }
        // Perform
        get_questions_from_levelAsIds(data)
            .then((res) => {
                // Handle response
                if (isNullOrUndefined(res) || isArrayNullUndefinedOrEmpty(res)) {
                    setQuestionsError(kEmptyResponseError)
                    sendErrorToSentry(kEmptyResponseError.sentry, 'in fetching Questions from ParameterContent')
                } else {
                    fetchQuestionsCallback(res)
                }
            })
            .catch((err) => {
                console.log(err)
                setQuestionsError(kFlashQuestionsError)
                sendErrorToSentry(kFlashQuestionsError.sentry)
            })
            .finally(() => {
                setIsQuestionsLoading(false)
            })
    }

    // ---- ---- ----
    // USER INTERACTION
    // ---- ---- ----
    // ---- Toggle LevelA : add it or delete it from the current selectedLevelAs list
    function toggleLevelA(currentLevelA) {
        if (selectedLevelAs.includes(currentLevelA)) {
            // Case : currentLevelA is already in the selectedLevelAs => delete
            const updatedList = selectedLevelAs.filter(levelA => levelA.id !== currentLevelA.id)
            setSelectedLevelAs(updatedList)
        } else {
            // Case : currentLevelA is not in selectedLevelAs => add
            // ---- Get currentLevelA in levelAs
            const index = levelAs.indexOf(currentLevelA)
            // ---- React rebuild components by changing the reference of the object
            // ---- Rebuild a whole new LevelAs list
            const updatedList = []
            selectedLevelAs.forEach(levelA => {
                updatedList.push(levelA)
            })
            updatedList.splice(index, 0, currentLevelA)
            // ---- Set state
            setSelectedLevelAs(updatedList)
        }
    }

    // ---- Select a specific subtheme
    function selectSubtheme(subtheme) {
        // Check input parameter
        if (isNullOrUndefined(subtheme))
            return
        // Set current selected Subtheme
        setSelectedSubtheme(subtheme)
        // Set current displayed LevelAs
        setLevelAs(subtheme["subthemeLevelAs"])
        // Set selectedLevelAs from the current selected Subtheme
        setSelectedLevelAs(subtheme["subthemeLevelAs"])
    }

    // ---- ---- ----
    // OTHERS
    // ---- ---- ----
    // ---- Reset screen global state
    function resetScreenState() {
        setSubthemes([])
        setSelectedSubtheme(null)
        setLevelAs([])
        setSelectedLevelAs([])
    }
}

// EXPORT
export default ParameterContent