import CorrectionStepScreen from "../../../../screens/flashScreens/CorrectionStepScreen";
import {useState} from "react";
import {isNullOrUndefined} from "../../../../services/Validator";
import {PageView} from "../../../../enums/PageView";
import {useResizeListener} from "../../../../listeners/SystemResizeListener";
import ResultsQuestionDivider from "../../../dividers/flashScreens/resultsStepScreen/ResultsQuestionDivider";
import ResultsQuestionItemChevronIcon from "../../../icons/flashScreens/resultsStepScreen/ResultsQuestionItemChevronIcon";
import ResultsQuestionItemLayout from "../../../layouts/flashScreens/resultsStepScreen/ResultsQuestionItemLayout";
import ResultsQuestionIndex from "../../../others/flashScreens/resultsStepScreen/ResultsQuestionIndex";
import ResultsQuestionStatementText from "../../../texts/flashScreens/resultsStepScreen/ResultsQuestionStatementText";
import {recordPageViewMetric} from "../../../../services/AnalyticsRecorder";

function ResultsQuestionItem({index, total, question}) {
    // Used in ResultsQuestionsList to display one single
    // question element that leads to correction

    // PROPERTIES
    const [isCorrectionModalOpen, setIsCorrectionModalOpen] = useState(false)
    const [isHovered, setIsHovered] = useState(false)

    // HOOKS
    // ---- Set listener for potential open Modal during screen resize
    useResizeListener(() => setIsCorrectionModalOpen(false))

    // BUILD
    if (isNullOrUndefined(question))
        return null
    return (
        <div className="w-full flex flex-col space-y-6">
            {/** CORRECTION MODAL */}
            <CorrectionStepScreen open={isCorrectionModalOpen}
                                  handleClose={() => setIsCorrectionModalOpen(false)}
                                  question={question} />
            {/** CORE */}
            <ResultsQuestionItemLayout setIsHovered={setIsHovered}
                                       openModal={() => openModal()}>
                <ResultsQuestionIndex number={index + 1}
                                      correctness={question?.selectedOption?.isCorrect} />
                <ResultsQuestionStatementText statement={question?.query} />
                <ResultsQuestionItemChevronIcon isHovered={isHovered} />
            </ResultsQuestionItemLayout>
            {/** BOTTOM DIVIDER IF NECESSARY */}
            {index === (total - 1) ? null : <ResultsQuestionDivider />}
        </div>
    );

    // METHODS
    // ---- Open modal
    function openModal() {
        // NAV : Open modal
        setIsCorrectionModalOpen(true)
        // METRICS : Record opening
        recordPageViewMetric(PageView.CORRECTION)
    }

}

// EXPORT
export default ResultsQuestionItem