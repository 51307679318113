// Api related configuration

// ---- Api related configuration values
export const analytics = {
    initial_domain: process.env.REACT_APP_ANALYTICS_URL,
    domain: process.env.REACT_APP_ANALYTICS_URL + "/",
    routes: {
        // PageView
        recordPageView: "pageView/record"
    }
}