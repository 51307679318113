
// CONSTANTS
// ---- flashScreen
export const kFlashSubthemesStatement = "Une erreur est survenue pendant le chargement des thèmes"
export const kFlashSubthemesReassurance = "Nous faisons notre possible pour résoudre le problème au plus vite"
export const kFlashSubthemesSentry = "Error in fetching Subthemes"

export const kFlashQuestionsStatement = "Une erreur est survenue pendant le chargement des questions"
export const kFlashQuestionsReassurance = "Nous faisons notre possible pour résoudre le problème au plus vite"
export const kFlashQuestionsSentry = "Error in fetching Questions"

// ---- knowledgesScreen
export const kKnowledgesStatement = "Aucune connaissance trouvée, une erreur est survenue"
export const kKnowledgesReassurance = "Nous faisons notre possible pour résoudre le problème au plus vite"
export const kKnowledgesSentry = "Error in fetching Knowledges"
// ---- System
export const kSystemGradesStatement = "Une erreur est survenue pendant le chargement des niveaux"
export const kSystemGradesReassurance = "Nous faisons notre possible pour résoudre le problème au plus vite"
export const kSystemGradesSentry = "Error in fetching Grades in "
// ---- Api
// ---- ---- Unknown
export const kApiUnknownStatement = "Une erreur serveur est survenue"
export const kApiUnknownReassurance = "Nous faisons notre possible pour résoudre le problème au plus vite"
export const kApiUnknownSentry = "Error, unknown from api in "
// ---- ---- Empty response
export const kEmptyResponseStatement = "Aucune donnée trouvée"
export const kEmptyResponseReassurance = "Nous allons faire au plus vite pour résoudre le problème"
export const kEmptyResponseSentry = "Error, empty response from api in "

// PROPERTIES
// ---- flashScreen
export const kFlashSubthemesError = {
    statement: kFlashSubthemesStatement,
    reassurance : kFlashSubthemesReassurance,
    sentry: kFlashSubthemesSentry,
}
export const kFlashQuestionsError = {
    statement: kFlashQuestionsStatement,
    reassurance : kFlashQuestionsReassurance,
    sentry: kFlashQuestionsSentry,
}

// ---- knowledgesScreen
export const kKnowledgesError = {
    statement: kKnowledgesStatement,
    reassurance : kKnowledgesReassurance,
    sentry: kKnowledgesSentry,
}

// ---- System
export const kSystemGradesError = {
    statement: kSystemGradesStatement,
    reassurance : kSystemGradesReassurance,
    sentry: kSystemGradesSentry,
}

// ---- Api
// ---- ---- Unknown
export const kApiUnknownError = {
    statement: kApiUnknownStatement,
    reassurance: kApiUnknownReassurance,
    sentry: kApiUnknownSentry
}
// ---- ---- Empty response
export const kEmptyResponseError = {
    statement: kEmptyResponseStatement,
    reassurance: kEmptyResponseReassurance,
    sentry: kEmptyResponseSentry
}