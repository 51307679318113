import {scanDeviceType} from "../helpers/ScreenHelper";
import {Environment} from "../enums/Environment";
import {Platform} from "../enums/Platform";
import {record_page_view_metric} from "../analytics/pageViewMetric";
import {isNullOrUndefined} from "./Validator";

// Used to centralize PageViewMetric related controllers and operations

// METHODS
// ---- Record PageViewMetric for landing page visit
export function recordPageViewMetric(pageName) {
    // Defensive measures
    const env = process.env.REACT_APP_ENV
    if (env !== Environment.DEV || env !== Environment.PROD) {
        return
    }
    const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL
    if (isNullOrUndefined(analyticsUrl) || analyticsUrl === "")
        return
    if (!pageName)
        return
    // Prepare
    let data = {
        visitedPage: pageName,
        platform: Platform.WEB,
        device: scanDeviceType()
    }
    // Perform
    record_page_view_metric(data)
        .then(() => {
            // console.log(res)
        }).catch((err) => {
            console.error(err)
    })
}