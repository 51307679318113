
// ---- Games
export const kGamesNumbers = {
    gameTimeLeft: 60,
    multiplicationAnswerMax: 3,
    multiplicationRecord: 50,
    overlayTimer: 4,
    relativeSumAnswerMax: 2,
    relativeSumRecord: 45,
}

// ---- SYSTEM
