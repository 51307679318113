import {generatePath} from "react-router";
import {makeRequest, Methods} from "../api/request";
import {analytics} from "./routes";

// List of methods triggering Analytics related api calls

// METHODS
// ---- Record page view based on visited page
export function record_page_view_metric(data) {
    const url = analytics.domain + generatePath(analytics.routes.recordPageView)
    return makeRequest(url, Methods.POST, data)
}