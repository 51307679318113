import * as Sentry from "@sentry/react";
import {Environment} from "../enums/Environment";

// METHODS
// ---- Send message to sentry with custom sentry error message
export const sendErrorToSentry = (sentryError, errorContext) => {
    const env = process.env.REACT_APP_ENV
    if (env === "" || env === Environment.FAKER) {
        return
    }
    Sentry.captureMessage(sentryError + (errorContext : ""), "fatal");
}