import {SystemSecondaryCustomButton} from "../../buttons/SystemCustomButton";
import {kFlashTexts} from "../../../constants/texts";
import {kButtonStyles} from "../../../constants/styles";

function SingleKnowledgeBottomBar({handleClose}) {
    // Used in SingleKnowledgeScreen to display modal
    // bottom bar with both back and launch flash action

    // BUILD
    return (
        <div className={"w-full border-t-1 border-greyLight5"}>
            <div className={"flex space-x-16 p-16"}>
                <SystemSecondaryCustomButton
                    label={kFlashTexts.back}
                    isDisabled={false}
                    isLoading={false}
                    isFullWidth={true}
                    sizeStyle={kButtonStyles.largeButtonStyle}
                    extraClass={"w-full"}
                    onButtonClick={() => handleClose()} />
                {/*<SystemPrimaryCustomButton*/}
                {/*    label={kFlashTexts.launchTheFlash}*/}
                {/*    isDisabled={false}*/}
                {/*    isLoading={false}*/}
                {/*    isFullWidth={true}*/}
                {/*    sizeStyle={kButtonStyles.largeButtonStyle}*/}
                {/*    extraClass={"w-full"}*/}
                {/*    onButtonClick={() => handleClose()} />*/}
            </div>
        </div>
    );
}

// EXPORT
export default SingleKnowledgeBottomBar