import KnowledgeDefinition from "./KnowledgeDefinition";

function SingleKnowledgeScreenContent({knowledge}) {
    // Used in SingleKnowledgeScreen to display Knowledge
    // related content

    // BUILD
    return (
        <div className={"flex flex-col justify-start p-24"}>
            <KnowledgeDefinition knowledge={knowledge} />
        </div>
    );
}

// EXPORT
export default SingleKnowledgeScreenContent