// PageViewMetric entity related enums

// ---- Event name
export const PageView = {
    // ---- Side content
    ABOUT: 'about',
    PRIVACY: 'privacy',
    LEGALS: 'legals',
    // ---- Main content
    LANDING: 'landing',
    KNOWLEDGES: 'knowledges',
    KNOWLEDGE: 'knowledge',
    FLASH: 'flash',
    RESULTS: 'results',
    CORRECTION: 'correction',
    GAMES: 'games',
    MULTIPLICATION_GAME: 'multiplication_game',
    RELATIVE_SUM_GAME: 'relative_sum_game'
}