import {Slide} from "@mui/material";
import Modal from "@mui/material/Modal";
import SingleKnowledgeBottomBar from "../components/bars/singleKnowledgeScreen/SingleKnowledgeBottomBar";
import SingleKnowledgeScreenContent from "../components/others/singleKnowledgeScreen/SingleKnowledgeScreenContent";

function SingleKnowledgeScreen({open, handleClose, knowledge}) {
    // Used in KnowledgesItem to display one single Knowledge
    // content

    // BUILD
    return (
        <Modal open={open}
               onClose={handleClose}>
            <Slide direction="left" in={open} mountOnEnter unmountOnExit>
                <div className="overflow-auto w-full h-[100svh] flex outline-none">
                    <div className={"cursor-pointer grow h-full"}
                         onClick={() => handleClose()}>
                    </div>
                    <div className={"bg-backgroundLight3 w-[400px] min-w-[400px] h-full flex flex-col justify-between space-y-16"}>
                        <SingleKnowledgeScreenContent knowledge={knowledge} />
                        <SingleKnowledgeBottomBar handleClose={handleClose} />
                    </div>
                </div>
            </Slide>
        </Modal>
    );
}

// EXPORT
export default SingleKnowledgeScreen