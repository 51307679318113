import {isArrayNullUndefinedOrEmpty, isNullOrUndefined} from "../../../services/Validator";
import {kApiUnknownError, kEmptyResponseError, kSystemGradesError} from "../../../constants/errors";
import {useEffect, useState} from "react";
import SystemScreenError from "../../errors/SystemScreenError";
import SystemNeoScreenLoader from "../../loaders/SystemNeoScreenLoader";
import {get_knowledges_for_grade} from "../../../api/knowledge";
import {kFakeFlashGrades, kFakeKnowledgesMap} from "../../../services/Faker";
import {get_flash_grades} from "../../../api/grade";
import KnowledgesGradesList from "../../lists/knowledgesScreen/KnowledgesGradesList";
import KnowledgesCoreContent from "./KnowledgesCoreContent";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";
import {GradePickerSize} from "../../../controllers/flashScreens/parameterStepScreen/ParameterGradeBadgeItemController";
import {sendErrorToSentry} from "../../../services/Sentry";
import {Environment} from "../../../enums/Environment";

function KnowledgesContent() {
    // Used in KnowledgesScreen to manage Knowledges related content

    // PROPERTIES
    // ---- Grades
    const [isLoading, setIsLoading] = useState(true)
    const [screenError, setScreenError] = useState(null)
    const [grades, setGrades] = useState([])
    const [selectedGrade, setSelectedGrade] = useState(null)
    // ---- Knowledges
    const [isKnowledgesLoading, setIsKnowledgesLoading] = useState(false)
    const [knowledgesError, setKnowledgesError] = useState(null)
    const [knowledgesMap, setKnowledgesMap] = useState(null)

    // HOOKS
    // ---- Set hook
    useEffect(() => {
        getFlashGrades()
    }, [])

    // BUILD
    if (!isNullOrUndefined(screenError))
        return <SystemScreenError error={screenError}
                                  retryCallback={() => getFlashGrades()} />
    if (isLoading)
        return <SystemNeoScreenLoader />
    return (
        <div className={"animate-appearSlow overflow-auto w-full grow flex flex-col justify-start items-center"}>
            <KnowledgesGradesList grades={grades}
                                  selectedGrade={selectedGrade}
                                  onGradeSelected={getPublishedKnowledgesForGrade}
                                  gradePickerSize={GradePickerSize.MEDIUM}
                                  extraClass={"mt-24"} />
            <SystemCustomDivider color={"bg-greyLight5"}
                                 extraClass={"mt-24"} />
            <KnowledgesCoreContent isKnowledgesLoading={isKnowledgesLoading}
                                   knowledgesError={knowledgesError}
                                   selectedGrade={selectedGrade}
                                   knowledgesMap={knowledgesMap} />
        </div>
    );

    // METHODS
    // ---- Get grades
    function getFlashGrades() {
        // <<<< For .env.faker ----
        if (process.env.REACT_APP_ENV === Environment.FAKER) {
            setGrades(kFakeFlashGrades)
            setIsLoading(false)
            setScreenError(null)
            return
        }
        // ---- For .env.faker >>>>

        // Switch loader on and clear error
        setIsLoading(true)
        setScreenError(null)
        // Perform
        get_flash_grades()
            .then((res) => {
                if (isNullOrUndefined(res) || isArrayNullUndefinedOrEmpty(res)) {
                    setScreenError(kEmptyResponseError)
                    sendErrorToSentry(kEmptyResponseError.sentry, "fetching Grades in knowledgesContent")
                } else {
                    setGrades(res)
                }
            }).catch((err) => {
                console.error(err)
                setScreenError(kSystemGradesError)
                sendErrorToSentry(kSystemGradesError.sentry, "in knowledgesContent")
        }).finally(() => {
            setIsLoading(false)
        })
    }

    // ---- Get Knowledges for Grade
    function getPublishedKnowledgesForGrade(pressedGrade) {
        // <<<< For .env.faker ----
        if (process.env.REACT_APP_ENV === Environment.FAKER) {
            setKnowledgesMap(kFakeKnowledgesMap)
            setIsKnowledgesLoading(false)
            setKnowledgesError(null)
            return
        }
        // ---- For .env.faker >>>>

        if (isNullOrUndefined(pressedGrade) || isNullOrUndefined(pressedGrade.id) || selectedGrade?.id === pressedGrade.id)
            return
        // Set selected Grade
        setSelectedGrade(pressedGrade)
        // Set loader on and clear potential error
        setIsKnowledgesLoading(true)
        setKnowledgesError(null)
        // Perform
        get_knowledges_for_grade(pressedGrade.id)
            .then((res) => {
                if (isNullOrUndefined(res) || isArrayNullUndefinedOrEmpty(res)) {
                    setKnowledgesError(kEmptyResponseError)
                    sendErrorToSentry(kEmptyResponseError.sentry, "fetching Knowledges in KnowledgesContent")
                } else {
                    setKnowledgesMap(res)
                }
            }).catch((err) => {
            console.error(err)
            setKnowledgesError(kApiUnknownError)
            sendErrorToSentry(kApiUnknownError.sentry, "fetching Knowledges in KnowledgesContent")
        }).finally(() => {
            setIsKnowledgesLoading(false)
        })
    }
}

// EXPORT
export default KnowledgesContent